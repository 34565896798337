import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '@components/Layout';
import FromPage from '../scenes/FormPage';

const FormPage = ({ data }) => {
  // console.log('data', data);

  const formpageContent = data.allPrismicFormPage.edges[0];

  if (!formpageContent) return null;
  const frompage = formpageContent.node;
  const { uid, id, type, alternate_languages, lang, data: pageData } = frompage;

  const activeDocMeta = { id, uid, lang, type, alternate_languages };
  const {
    metatitle,
    metadescription,
    canonical,
    // body: pageContent,
    form_page_link,
  } = pageData;

  //   console.log('pageContent', pageContent);
  // console.log('form_page_link', form_page_link);

  return (
    <>
      <Layout
        activeDocMeta={activeDocMeta}
        metatitle={metatitle}
        metadescription={metadescription}
        canonical={canonical}
      >
        <FromPage
          link={form_page_link}
          // canonical={canonical}
          // metatitle={metatitle}
        />
      </Layout>
    </>
  );
};

export default FormPage;

FormPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid: String, $lang: String) {
    allPrismicFormPage(filter: { uid: { eq: $uid }, lang: { eq: $lang } }) {
      edges {
        node {
          uid
          type
          id
          lang
          alternate_languages {
            id
            lang
            uid
            type
          }
          data {
            metatitle {
              text
            }
            metadescription {
              text
            }
            canonical {
              text
            }
            title {
              text
            }
            form_page_link {
              text
            }
          }
        }
      }
    }
  }
`;
