import React from 'react';
import PropTypes from 'prop-types';
import style from './FromPage.module.scss';

const FromPage = ({ link }) => {
  // console.log('link', link);

  // function Movie({ id }) {
  //   console.log('id', id);

  //   let formRef = useRef();

  //   // console.log({ formRef });

  //   useEffect(() => {
  //     let progressbar = document.getElementById(id);

  //     console.log('progressbar', progressbar);

  //     if (progressbar) {
  //       // let per = `${(e.current / e.total) * 100}%`;
  //       // progressbar.style.width = `'88px'`;
  //       // progressbar.style.transition = `width .1s linear,height .2s`;
  //     }
  //   }, []);

  //   return (
  //     <>
  //       <iframe
  //         id={id}
  //         ref={formRef}
  //         title="Partner Onboarding Form"
  //         allowtransparency="true"
  //         allowFullScreen="true"
  //         allow="geolocation; microphone; camera"
  //         src="https://form.jotform.com/202922344366353"
  //         frameBorder="0"
  //         style="min-width:100%;max-width:100%;height:2450px;border:none;"
  //         scrolling="no"
  //         className="form_wrapper"
  //       ></iframe>
  //     </>
  //   );
  // }

  // const FromComponent = React.memo(Movie);

  return (
    <div className={style.wrapper}>
      {link && (
        <iframe
          id="JotFormIFrame-202922344366353"
          title="Partner Onboarding Form"
          role="form"
          // onLoad="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowFullScreen="true"
          allow="geolocation; microphone; camera"
          src={link?.text}
          frameBorder="0"
          style="min-width:100%;max-width:100%;height:2450px;border:none;"
          scrolling="no"
        ></iframe>
      )}

      {/* <FromComponent id="otFormIFrame-202922344366353" /> */}
    </div>
  );
};

export default FromPage;

FromPage.propTypes = {
  content: PropTypes.array.isRequired,
  link: PropTypes.string,
};
